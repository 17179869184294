import ReferAFriendAd from 'lib/_content/images/banners/refer-a-friend.svg';
import CashbackRewardAd from 'lib/_content/images/banners/cashback-rewards.svg';
import MoneyMakers from 'lib/_content/images/banners/money-makers.svg';
import FreshBanner from 'lib/_content/images/banners/freshStartFestBanner.svg';
import { SUITS_ME_MONEY_MAKERS, SUITS_ME_FRESH_START_FEST } from 'lib/constants/routes';
import { MORE_CASHBACK_REWARDS, MORE_REFER_A_FRIEND } from './routes';

export const advertsList = [
    {
        subtitle: 'Money makers',
        title: 'Money makers',
        background: MoneyMakers,
        link: SUITS_ME_MONEY_MAKERS,
        isExternal: true,
    },
    {
        subtitle: 'Sign up for',
        title: 'Suitsme Rewards',
        background: ReferAFriendAd,
        link: MORE_REFER_A_FRIEND,
        isExternal: false,
    },
    {
        subtitle: 'Second special offer',
        title: 'Special reward',
        background: CashbackRewardAd,
        link: MORE_CASHBACK_REWARDS,
        isExternal: false,
    },
];

export const freshStartFestBanner = {
    title: 'Fresh Start Fest',
    background: FreshBanner,
    link: SUITS_ME_FRESH_START_FEST,
    isExternal: true,
};
