import { Link } from 'react-router-dom';
import analytics from 'utils/analytics';
import useAdverts from '../hooks/useAdverts';
import LeftArrow from 'components/shared/generic/arrow/presentational/Left';
import RightArrow from 'components/shared/generic/arrow/presentational/Right';
import { freshStartFestBanner } from 'constants/shared/adverts';
import { checkIfSameDay } from 'lib/utils/customDate';

const ReloadableLink = props => {
    const { isExternal, ...linkProps } = props;    
    return isExternal ? (
        <a {...linkProps} href={String(props.to.pathname)}>
            {props.children}
        </a>
    ) : (
        <Link {...linkProps}>{props.children}</Link>
    );
};

const Adverts = ({ list }) => {
    const isTodayTargetDate = checkIfSameDay("2025-02-21");
    const { currentSlide, handleChange } = useAdverts(list);
  
    const renderAd = (ad, index) => (
      <ReloadableLink
        key={ad.title}
        isExternal={ad.isExternal}
        to={{ pathname: ad.link, state: { backRoute: '/' } }}
      >
        <img
          role="presentation"
          className="item"
          src={ad.background}
          alt={`Suits Me Advert ${index + 1}`}
          onKeyDown={() => analytics.sendLinkEvent('Adverts Click', ad.title)}
          onClick={() => analytics.sendLinkEvent('Adverts Click', ad.title)}
        />
      </ReloadableLink>
    );
  
    return (
      <div className="adverts-container">
        {isTodayTargetDate ? (
          <div className="adverts-grid">
            {list.slice(currentSlide, currentSlide + 2).map(renderAd)}
            <LeftArrow onClick={() => handleChange(false)} disabled={currentSlide <= 0} />
            <RightArrow onClick={() => handleChange(true)} disabled={currentSlide >= list.length - 2} />
          </div>
        ) : (
          <ReloadableLink isExternal key={freshStartFestBanner.title} to={{ pathname: freshStartFestBanner.link, state: { backRoute: '/' } }}>
            <img
              role="presentation"
              className="item"
              src={freshStartFestBanner.background}
              alt="Suits Me Advert"
              onKeyDown={() => analytics.sendLinkEvent('Adverts Click', freshStartFestBanner.title)}
              onClick={() => analytics.sendLinkEvent('Adverts Click', freshStartFestBanner.title)}
            />
          </ReloadableLink>
        )}
      </div>
    );
  };

export default Adverts;
